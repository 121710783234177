import React from 'react';
import MainLayout from '@/layouts/MainLayout';
import { Hero } from '@/pageComponents/MainPage/Hero';
import { AboutUs } from '@/pageComponents/MainPage/AboutUs';
import { HowItWorks } from '@/pageComponents/MainPage/HowItWorks';
import { Divider } from '@/pageComponents/MainPage/Divider';
import { FaqSection } from '@/pageComponents/MainPage/FaqSection';
import { ContactUs } from '@/components/ContactUs';
import { Seo } from '@/components/Seo';
import { MainHeading } from '@/components/MainHeading';

const Home = ({ pageContext }) => {
  const {
    locale,
    seo,
    heroSection,
    aboutUs,
    howItWorks,
    dividerImage,
    dividerImageMobile,
    faqSection,
    contactUs,
  } = pageContext.data;

  return (
    <MainLayout isTransparentHeader={true} locale={locale}>
      <Seo data={seo} />
      <MainHeading text={seo.mainHeading} />
      <Hero data={heroSection} />
      <AboutUs data={aboutUs} />
      <HowItWorks data={howItWorks} />
      <Divider image={dividerImage} mobileImage={dividerImageMobile} />
      <FaqSection data={faqSection} />
      <ContactUs sectionData={contactUs} />
    </MainLayout>
  );
};

export default Home;
