import { LocaleContext } from '@/contexts/LocaleContext';
import { graphql, useStaticQuery } from 'gatsby';
import { useContext } from 'react';

interface ReasonData {
  id: string;
  reason: string;
  locale: string;
}

export function useContactReasons(): ReasonData[] {
  const locale = useContext(LocaleContext);
  const { allStrapiContactReasons } = useStaticQuery(graphql`
    query {
      allStrapiContactReasons {
        nodes {
          id
          reason
          locale
        }
      }
    }
  `);

  return allStrapiContactReasons.nodes.filter((item) => item.locale === locale);
}
