import { CurrentCarContext } from '@/contexts/CurrentCarContext';
import { LocaleContext } from '@/contexts/LocaleContext';
import { getArrayWithUniquiElems } from '@/helpers/getArrayWithUniquiElems';
import { useLocaleData } from '@/hooks/useLocaleData';
import { SelectValue } from '@/types';
import React, { FC, useContext, useMemo, useRef, useState } from 'react';
import { SelectInstance } from 'react-select';
import { object, string } from 'yup';
import { LastStepModal } from '../LastStepModal';
import { Button, CustomSelect, Root, SelectsWrapper, Title } from './styles';
import { useCookies } from 'react-cookie';
import { navigate } from 'gatsby';
import { getLocaleUrl } from '@/helpers/getLocaleUrl';
import dayjs from 'dayjs';
import { useEvDatabaseData } from '@/hooks/useEvDatabaseData';

interface ModelCarFormProps {
  className?: string;
}

let carSchema = object({
  brand: string().required(),
  model: string().required(),
});

const ModelCarForm: FC<ModelCarFormProps> = ({ className }) => {
  const [cookies] = useCookies(['isViewed']);
  const locale = useContext(LocaleContext);
  const [currentCar, setCurrentCar] = useContext(CurrentCarContext);

  const dataLocale = useMemo(
    () => useLocaleData(locale, 'modelCarForm'),
    [locale],
  );
  const [isOpenLastStepModal, setIsOpenLastStepModal] = useState(false);
  const [hasData, setHasData] = useState(false);

  const modelsSelectorRef = useRef<SelectInstance | null>(null);

  const evModels = useEvDatabaseData();
  const [models, setModels] = useState<any[]>([]);

  const [brandValue, setBrandValue] = useState('');
  const [modelValue, setModelValue] = useState('');

  const carState = {
    brand: brandValue,
    model: modelValue,
  };

  const isValidCardState = carSchema.isValidSync(carState);

  const evBrands = getArrayWithUniquiElems(
    evModels.map((item) => item.Vehicle_Make),
  );

  const filterModelsByBrand = (brand: string) =>
    evModels.filter((item) => item.Vehicle_Make === brand);

  const brandOptions = evBrands.map((item) => ({
    value: item,
    label: item,
  }));

  const modelOptions = models.map((item) => ({
    value: item.Vehicle_ID,
    label: `${item.Vehicle_Model} ${
      item.Vehicle_Model_Version ? item.Vehicle_Model_Version : ''
    } ${dayjs(`01-${item.Availability_Date_From}`, 'DD-MM-YYYY').get('year')}`,
  }));

  const getCarById = (id: number) =>
    evModels.find((item) => item.Vehicle_ID === id);

  const handleChangeBrandSelect = (e: SelectValue) => {
    modelsSelectorRef.current.clearValue();
    const currentModels = filterModelsByBrand(e.label);

    setHasData(false);
    setModels(currentModels);
    setBrandValue(e.label);
    setModelValue('');
  };

  const handleChangeModelSelect = (e: SelectValue) => {
    if (e) {
      setModelValue(e.label);
      setHasData(true);

      setCurrentCar(getCarById(e.value));
    }
  };

  return (
    <Root className={className}>
      <LastStepModal
        isOpen={isOpenLastStepModal}
        onClose={() => setIsOpenLastStepModal(false)}
      />
      <Title>{dataLocale.title}</Title>
      <SelectsWrapper>
        <CustomSelect
          placeholder={dataLocale.placeholderBrand}
          options={brandOptions}
          defaultValue={brandValue}
          onChange={handleChangeBrandSelect}
        />
        <CustomSelect
          ref={modelsSelectorRef}
          placeholder={dataLocale.placeholderModel}
          options={modelOptions}
          onChange={handleChangeModelSelect}
          noOptionsMessage={() => dataLocale.modelNoOptionsMessage}
        />
      </SelectsWrapper>
      <Button
        text={dataLocale.buttonText}
        customTheme="primarySmall"
        disabled={!isValidCardState || !hasData}
        onClick={() => {
          cookies.isViewed
            ? navigate(getLocaleUrl(locale, '/car-info'), {
                state: {
                  carData: currentCar,
                },
              })
            : setIsOpenLastStepModal(true);
        }}
      />
    </Root>
  );
};

export default ModelCarForm;
