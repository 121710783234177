import { CustomLink } from '@/components/CustomLink';
import { BUTTONS_THEME, COLORS } from '@/constants';
import { Arrow } from '@/ui/iconComponents/Arrow';
import { Link } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';

interface UiTextLinkProps {
  text: string;
  href: string;
  className?: string;
}

const UiTextLink: FC<UiTextLinkProps> = ({ text, href, className }) => {
  return (
    <Root className={className} to={href}>
      {text}
      <StyledArrow />
    </Root>
  );
};

const Root = styled(CustomLink)`
  ${BUTTONS_THEME.linkText};
`;

const StyledArrow = styled(Arrow)`
  position: absolute;
  right: 19px;
  top: 50%;
  transform: translateY(-50%);
`;

export default UiTextLink;
