import React, { FC } from 'react';
import styled from 'styled-components';
import carThunder from '@/assets/json/car-thunder.json';
import Lottie from 'react-lottie';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import { Container } from '@/components/Container';
import { COLORS, TYPOGRAPHY } from '@/constants';
import { ImageDataValue } from '@/types';
import ReactMarkdown from 'react-markdown';
import { Image } from '@/components/Image';
import { ModelCarForm } from './components/ModelCarForm';

import 'swiper/css';

interface HeroProps {
  data: {
    title: string;
    sliderImages: ImageDataValue[];
  };
}

const Hero: FC<HeroProps> = ({ data }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: carThunder,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Root>
      <StyledContainer>
        <Title>
          <ReactMarkdown>{data.title}</ReactMarkdown>
        </Title>
        <StyledModelCardForm />
      </StyledContainer>
      <Swiper
        allowTouchMove={false}
        modules={[Autoplay]}
        spaceBetween={0}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        slidesPerView={1}
      >
        <SwiperSlide>
          {({ isActive }) => {
            return <Lottie options={defaultOptions} isStopped={!isActive} />;
          }}
        </SwiperSlide>
        {data.sliderImages.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <Image localFile={item?.localFile} alt="ebee" loading="eager" />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Root>
  );
};

const Root = styled.section`
  height: 100vh;
  /* min-height: 750px; */
  margin-top: -84px;
  background-color: ${COLORS.mediumPurple};
  position: relative;
  .swiper {
    height: 100%;
    pointer-events: none;
  }
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
  .swiper-slide {
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    div[aria-label='animation'] {
      width: 1440px !important;

      position: absolute;
      right: 0;
    }
  }

  /* @media (min-width: 1024px) and (min-height: 800px) {
    height: 800px;
  } */
  @media (max-width: 1200px) {
    .swiper-slide {
      div[aria-label='animation'] {
        width: 1100px !important;
      }
    }
  }
  @media (max-width: 1024px) {
    height: 850px;
    min-height: 850px;
  }
  @media (max-width: 900px) {
    .swiper-slide {
      div[aria-label='animation'] {
        width: 900px !important;
      }
    }
  }
  @media (max-width: 768px) {
    .swiper-slide {
      div[aria-label='animation'] {
        opacity: 0.6;
        width: 500px !important;
      }
    }
  }
  @media (max-width: 500px) {
    margin-top: -117px;
  }
`;

const StyledContainer = styled(Container)`
  max-width: none;
  padding-left: 7.5vw;
  padding-right: 7.5vw;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  @media (max-width: 1300px) {
    padding-left: 50px;
    padding-right: 50px;
  }
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
    bottom: auto;
    top: 0;
    left: 0;
    transform: none;
    padding-top: 140px;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 140px;
  }
  @media (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const Title = styled.div`
  /* max-width: 550px; */
  max-width: 38.19vw;
  margin-right: 20px;
  h1,
  h2,
  p {
    font-weight: 600;
    /* font-size: 58px;
    line-height: 88px; */
    font-size: 4.02vw;
    line-height: 6.11vw;
    color: ${COLORS.white};
    strong {
      color: ${COLORS.mediumPurple};
      display: inline-block;
      position: relative;
      z-index: 1;
      ::before {
        content: '';
        position: absolute;
        width: calc(100% + 20px);
        height: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        background-color: ${COLORS.lightPurple};
        border-radius: 20px;
        z-index: -1;
      }
    }
  }
  @media (max-width: 1024px) {
    margin-right: 0;
    margin-bottom: 40px;
    max-width: none;
    h1,
    h2,
    p {
      ${TYPOGRAPHY.title2SemiBold48}
    }
  }
  @media (max-width: 900px) {
    padding-left: 20px;
    h1,
    h2,
    p {
      ${TYPOGRAPHY.title3SemiBold32};
      strong::before {
        border-radius: 11px;
        width: calc(100% + 10px);
      }
    }
  }

  @media (max-width: 374px) {
    padding-left: 0;
  }
`;

const StyledModelCardForm = styled(ModelCarForm)`
  @media (max-width: 1024px) {
    align-self: center;
  }
`;

export default Hero;
