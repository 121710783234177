import { Container } from '@/components/Container';
import { TagSection } from '@/components/TagSection';
import { COLORS, TYPOGRAPHY } from '@/constants';
import { ImageDataValue } from '@/types';
import { url } from 'inspector';
import React, { FC } from 'react';
import styled from 'styled-components';

interface AboutUsProps {
  data: {
    title: string;
    tagText: string;
    firstDescription: string;
    secondDescription: string;
    image: ImageDataValue;
  };
}

const AboutUs: FC<AboutUsProps> = ({ data }) => {
  return (
    <section>
      <StyledContainer>
        <StyledTagSection text={data.tagText} />
        <Title>{data.title}</Title>
        <ImageWrap
          style={{ backgroundImage: `url(${data.image?.localFile.publicURL})` }}
        ></ImageWrap>
        <DescriptionWrap>
          <Description>{data.firstDescription}</Description>
          <Description>{data.secondDescription}</Description>
        </DescriptionWrap>
      </StyledContainer>
    </section>
  );
};

const StyledContainer = styled(Container)`
  padding-top: 120px;
  padding-bottom: 75px;
  @media (max-width: 1024px) {
    padding-top: 100px;
    padding-bottom: 50px;
  }
`;

const StyledTagSection = styled(TagSection)`
  margin-bottom: 20px;
  @media (max-width: 1024px) {
    margin-bottom: 16px;
  }
`;

const Title = styled.h2`
  ${TYPOGRAPHY.title1SemiBold64};
  color: ${COLORS.black2};
  margin-bottom: 60px;
  @media (max-width: 1024px) {
    ${TYPOGRAPHY.title2SemiBold48};
    margin-bottom: 40px;
  }
  @media (max-width: 768px) {
    ${TYPOGRAPHY.headlineSemiBold22};
    margin-bottom: 32px;
  }
`;

const ImageWrap = styled.div`
  min-height: 352px;
  background-color: ${COLORS.mediumPurple};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 40px;
  overflow: hidden;
  margin-bottom: 60px;
  @media (max-width: 768px) {
    min-height: 137px;
    margin-bottom: 16px;
    border-radius: 16px;
  }
`;

const DescriptionWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  @media (max-width: 768px) {
    display: block;
  }
`;

const Description = styled.p`
  ${TYPOGRAPHY.body1Regular18};
  color: ${COLORS.black2};
  @media (max-width: 768px) {
    margin-bottom: 24px;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

export default AboutUs;
