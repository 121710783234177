import { Modal } from '@/components/Modal';
import { TextField } from '@/components/TextField';
import { COLORS, TYPOGRAPHY } from '@/constants';
import { CurrentCarContext } from '@/contexts/CurrentCarContext';
import { LocaleContext } from '@/contexts/LocaleContext';
import {
  composeValidators,
  emailValidate,
  nameValidate,
  requiredValidate,
} from '@/helpers/fieldValidate';
import { useLocaleData } from '@/hooks/useLocaleData';
import { UiButton } from '@/ui/components/UiButton';
import { Cross } from '@/ui/iconComponents/Cross';
import { navigate } from 'gatsby';
import { useMutation } from 'graphql-hooks';
import React, { FC, useContext, useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import styled from 'styled-components';
import { useCookies } from 'react-cookie';
import dayjs from 'dayjs';
import { getLocaleUrl } from '@/helpers/getLocaleUrl';
import { FieldSelect } from '@/components/FieldSelect';
import { useReasonsForRequestVehicleData } from '@/hooks/useReasonsForRequestVehicleData';

interface LastStepModalProps {
  isOpen: boolean;

  onClose: () => void;
}

interface FormValues {
  name: string;
  email: string;
  reason: {
    label: string;
    value: string;
  };
}

const CREATE_USER_REQUESTED_DATA_MUTATION = `mutation CreateUserRequesteData($name: String!, $email: String!, $car: String!, $reason: String!) {
  createUsersRequestedDatum(input: {data: {
    name: $name,
    email: $email,
    car: $car,
    reason: $reason
  }}) {
    usersRequestedDatum {
      id
      name
      car
      email
      reason
    }
  }
}`;

const LastStepModal: FC<LastStepModalProps> = ({ isOpen, onClose }) => {
  const [cookies, setCookie] = useCookies(['isViewed']);
  const locale = useContext(LocaleContext);
  const [carData] = useContext(CurrentCarContext);
  const [createUserRequestedData] = useMutation(
    CREATE_USER_REQUESTED_DATA_MUTATION,
  );
  const dataLocale = useMemo(
    () =>
      useLocaleData(locale, ['lastStepModal', 'inputPlaceholders', 'buttons']),
    [locale],
  );
  const reasonOptions = useReasonsForRequestVehicleData().map((item) => ({
    value: item.text,
    label: item.text,
  }));

  const handleSubmit = async (values: FormValues) => {
    const carName = `${carData.Vehicle_Make} ${carData.Vehicle_Model} ${carData?.Vehicle_Model_Version}`;

    const { data, error } = await createUserRequestedData({
      variables: { ...values, car: carName, reason: values.reason.label },
    });
    if (data) {
      onClose();
      setCookie('isViewed', true, {
        expires: dayjs().set('year', 2038).toDate(),
      });
      navigate(getLocaleUrl(locale, '/car-info'), {
        state: {
          carData,
        },
      });
    }
  };
  return (
    <StyledModal
      isOpen={isOpen}
      onRequestClose={() => onClose()}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    >
      <div>
        <Head>
          <Title>{dataLocale.lastStepModal.title}</Title>
          <button onClick={() => onClose()}>
            <Cross />
          </button>
        </Head>
        <Content>
          <Form
            onSubmit={handleSubmit}
            render={({ submitting, invalid, handleSubmit }) => (
              <form>
                <FieldList>
                  <StyledField
                    name="name"
                    type="text"
                    placeholder={dataLocale.inputPlaceholders.fullName}
                    validate={composeValidators(requiredValidate, nameValidate)}
                    component={TextField}
                  />
                  <StyledField
                    name="email"
                    type="email"
                    placeholder={dataLocale.inputPlaceholders.email}
                    validate={composeValidators(
                      requiredValidate,
                      emailValidate,
                    )}
                    component={TextField}
                  />

                  <StyledField
                    name="reason"
                    isSearchable={false}
                    placeholder={dataLocale.lastStepModal.reasonPlaceholder}
                    validate={composeValidators(requiredValidate)}
                    options={reasonOptions}
                    component={FieldSelect}
                  />
                </FieldList>
                <StyledButton
                  text={dataLocale.buttons.confirm}
                  customTheme="primary"
                  disabled={submitting || invalid}
                  onClick={handleSubmit}
                />
              </form>
            )}
          />
        </Content>
      </div>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  width: 100%;
  max-width: 512px;
`;

const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 70px;
  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
`;

const Title = styled.p`
  ${TYPOGRAPHY.headlineMedium22};
  color: ${COLORS.black1};
  margin-right: 30px;
  @media (max-width: 768px) {
    margin-right: 10px;
    ${TYPOGRAPHY.body2Medium16}
  }
`;

const Content = styled.div``;

const FieldList = styled.div`
  margin-bottom: 40px;
  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
`;

const StyledField = styled(Field)`
  margin-bottom: 30px;
  :last-child {
    margin-bottom: 0;
  }
  @media (max-width: 768px) {
    margin-bottom: 15px;
  }
`;

const StyledButton = styled(UiButton)`
  width: 100%;
`;

export default LastStepModal;
