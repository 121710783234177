import { Accordion } from '@/components/Accordion';
import { Container } from '@/components/Container';
import { ShopCard } from '@/components/ShopCard';
import { TagSection } from '@/components/TagSection';
import { COLORS, TYPOGRAPHY } from '@/constants';
import { useFaqs } from '@/hooks/useFaqs';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';

interface FaqSectionProps {
  data: {
    tagText: string;
    title: string;
  };
}

const FaqSection: FC<FaqSectionProps> = ({ data }) => {
  const faqs = useFaqs();

  return (
    <section>
      <StyledContainer>
        <StyledTagSection text={data.tagText} />
        <Title>{data.title}</Title>
        <StyledAccordion data={faqs} />
      </StyledContainer>
    </section>
  );
};

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 90px;
  padding-bottom: 90px;
  @media (max-width: 1024px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

const StyledTagSection = styled(TagSection)`
  margin-bottom: 20px;
  @media (max-width: 768px) {
    margin-bottom: 24px;
  }
`;

const Title = styled.h2`
  ${TYPOGRAPHY.title1SemiBold64};
  color: ${COLORS.black2};
  margin-bottom: 80px;
  @media (max-width: 1024px) {
    ${TYPOGRAPHY.title2SemiBold48};
  }
  @media (max-width: 768px) {
    ${TYPOGRAPHY.headlineSemiBold22};
    margin-bottom: 36px;
  }
`;

const StyledAccordion = styled(Accordion)`
  max-width: 1016px;
`;

export default FaqSection;
