import { graphql, useStaticQuery } from 'gatsby';

interface CarData {
  Vehicle_ID: number;
  Availability_Date_From: string | null;
  Vehicle_Make: string | null;
  Vehicle_Model: string | null;
  Vehicle_Model_Version: string | null;
  Battery_Capacity_Useable: number | null;
  Range_Real: number | null;
  Fastcharge_Power_Max: number | null;
  Fastcharge_ChargeSpeed: number | null;
  Efficiency_Real: number | null;
  Charge_Standard_Power: number | null;
  Charge_Standard_ChargeSpeed: number | null;
  Images: string[];
}

export function useEvDatabaseData(): CarData[] {
  const { strapiEvDatabaseData } = useStaticQuery(graphql`
    query {
      strapiEvDatabaseData {
        data {
          Vehicle_ID
          Availability_Date_From
          Vehicle_Make
          Vehicle_Model
          Vehicle_Model_Version

          Battery_Capacity_Useable
          Range_Real
          Fastcharge_Power_Max
          Fastcharge_ChargeSpeed
          Efficiency_Real
          Charge_Standard_Power
          Charge_Standard_ChargeSpeed
          Images
        }
      }
    }
  `);

  return strapiEvDatabaseData.data;
}
