import { COLORS, TYPOGRAPHY } from '@/constants';
import { StyledSelect } from '@/ui/components/StyledSelect';
import { UiButton } from '@/ui/components/UiButton';
import styled from 'styled-components';

export const Root = styled.div`
  max-width: 34.44vw;
  background-color: rgba(248, 248, 253, 0.2);
  backdrop-filter: blur(8px);
  border-radius: 20px;
  padding: 2.08vw 2.77vw;
  @media (max-width: 1200px) {
    padding: 20px 25px;
  }
  @media (max-width: 1024px) {
    max-width: 496px;
  }
  @media (max-width: 500px) {
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 14px;
  }
`;

export const Title = styled.p`
  font-size: 1.94vw;
  line-height: 2.36vw;
  font-weight: 600;
  color: ${COLORS.white};
  margin-bottom: 2.77vw;
  @media (max-width: 1024px) {
    ${TYPOGRAPHY.title4Medium28};
    margin-bottom: 40px;
  }
  @media (max-width: 500px) {
    ${TYPOGRAPHY.body2Semibold16};
    margin-bottom: 20px;
  }
`;

export const SelectsWrapper = styled.div`
  margin-bottom: 2.77vw;
  @media (max-width: 1024px) {
    margin-bottom: 40px;
  }
  @media (max-width: 500px) {
    margin-bottom: 20px;
  }
`;

export const CustomSelect = styled(StyledSelect)`
  margin-bottom: 2.08vw;
  :last-child {
    margin-bottom: 0;
  }
  @media (max-width: 1024px) {
    margin-bottom: 30px;
  }
  @media (max-width: 500px) {
    margin-bottom: 12px;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

export const Button = styled(UiButton)`
  width: 100%;
  font-size: 1.04vw;
  line-height: 1.32vw;
  padding: 1.04vw;
  @media (max-width: 1024px) {
    ${TYPOGRAPHY.body2Regular16};
    padding: 16px 37px;
  }
`;
