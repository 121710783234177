import { Container } from '@/components/Container';
import { Image } from '@/components/Image';
import { ImageDataValue } from '@/types';
import React, { FC } from 'react';
import styled from 'styled-components';

interface DividerProps {
  image: ImageDataValue;
  mobileImage: ImageDataValue;
}

interface ImageWrapProps {
  $desktopImage: string;
  $mobileImage: string;
}

const Divider: FC<DividerProps> = ({ image, mobileImage }) => {
  return (
    <section>
      <StyledContainer>
        <ImageWrap
          $desktopImage={image?.localFile.publicURL}
          $mobileImage={mobileImage?.localFile.publicURL}
        />
      </StyledContainer>
    </section>
  );
};

const Root = styled.section``;

const StyledContainer = styled(Container)`
  padding-top: 90px;
  padding-bottom: 90px;
  @media (max-width: 1024px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

const ImageWrap = styled.div<ImageWrapProps>`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center right;
  width: 100%;
  min-height: 388px;
  overflow: hidden;
  border-radius: 40px;
  background-image: url(${({ $desktopImage }) => $desktopImage});
  @media (max-width: 1024px) {
    min-height: 280px;
  }
  @media (max-width: 768px) {
    background-position: top right;
    background-image: url(${({ $mobileImage }) => $mobileImage});
    border-radius: 16px;
  }
  @media (max-width: 600px) {
    min-height: 250px;
  }
  @media (max-width: 500px) {
    min-height: 186px;
  }
`;

export default Divider;
