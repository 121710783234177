import { Container } from '@/components/Container';
import { TagSection } from '@/components/TagSection';
import { COLORS, LINKS, TYPOGRAPHY } from '@/constants';
import { useSolutionCases } from '@/hooks/useSolutionCases';
import { UiLink } from '@/ui/components/UiLink';
import React, { FC } from 'react';
import styled from 'styled-components';
import { Case } from './components/Case';

interface HowItWorksProps {
  data: {
    tagText: string;
    title: string;
    buttonText: string;
  };
}

const HowItWorks: FC<HowItWorksProps> = ({ data }) => {
  const cases = useSolutionCases();

  return (
    <section>
      <StyledContainer>
        <StyledTagSection text={data.tagText} />
        <Title>{data.title}</Title>
        <UserCases>
          {cases.map((item) => (
            <StyledCase key={item.id} data={item} />
          ))}
        </UserCases>
        {LINKS.shop && (
          <StyledUiLink text={data.buttonText} link={LINKS.shop} />
        )}
      </StyledContainer>
    </section>
  );
};

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 75px;
  padding-bottom: 90px;
  @media (max-width: 1024px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

const StyledTagSection = styled(TagSection)`
  margin-bottom: 20px;
  @media (max-width: 1024px) {
    margin-bottom: 24px;
  }
`;

const Title = styled.h2`
  ${TYPOGRAPHY.title1SemiBold64};
  color: ${COLORS.black2};
  margin-bottom: 100px;
  max-width: 750px;
  @media (max-width: 1024px) {
    ${TYPOGRAPHY.title2SemiBold48};
    margin-bottom: 40px;
  }
  @media (max-width: 768px) {
    ${TYPOGRAPHY.headlineSemiBold22};
    margin-bottom: 32px;
  }
`;

const UserCases = styled.div`
  counter-reset: userCase;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px 24px;
  margin-bottom: 60px;
  @media (max-width: 1300px) {
    gap: 32px;
  }

  @media (max-width: 768px) {
    display: block;
    margin-bottom: 40px;
  }
`;

const StyledCase = styled(Case)`
  @media (max-width: 768px) {
    margin-bottom: 32px;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

const StyledUiLink = styled(UiLink)`
  font-size: 15px;
  line-height: 19px;
  align-self: center;
  padding-top: 14px;
  padding-bottom: 14px;
  width: 100%;
  max-width: 300px;
  @media (max-width: 400px) {
    max-width: none;
  }
`;

export default HowItWorks;
