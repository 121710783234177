import { LocaleContext } from '@/contexts/LocaleContext';
import { ImageDataValue } from '@/types';
import { graphql, useStaticQuery } from 'gatsby';
import { useContext } from 'react';

export interface SolutionCase {
  locale: string;
  id: number;
  userCaseText: string;
  title: string;
  description: string;
  linkText: string;

  solutionDetail: {
    title: string;
  };
  image: ImageDataValue;
}

export function useSolutionCases(): SolutionCase[] {
  const locale = useContext(LocaleContext);
  const { allStrapiSolutionsCases } = useStaticQuery(graphql`
    query {
      allStrapiSolutionsCases {
        nodes {
          locale
          id
          userCaseText
          title
          description
          linkText
          solutionDetail: business_solutions_detail {
            title
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
              extension
              publicURL
            }
          }
        }
      }
    }
  `);

  return allStrapiSolutionsCases.nodes.filter((item) => item.locale === locale);
}
