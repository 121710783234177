import React, { FC } from 'react';
import { COLORS, LINKS, TYPOGRAPHY } from '@/constants';
import styled from 'styled-components';
import { UiTextLink } from '@/ui/components/UiTextLink';
import { Image } from '@/components/Image';
import { convertToSlug } from '@/helpers/convertToSlug';
import { SolutionCase } from '@/hooks/useSolutionCases';

interface CaseProps {
  data: SolutionCase;
  className?: string;
}

const Case: FC<CaseProps> = ({ data, className }) => {
  const solutionTitle = data.solutionDetail ? data.solutionDetail.title : '';
  const link = solutionTitle
    ? `${LINKS.businessSolutionPage}/#${convertToSlug(solutionTitle)}`
    : LINKS.evUserSolutionPage;

  return (
    <Root className={className}>
      <Wrapper>
        <ImageWrapper>
          <Image localFile={data.image?.localFile} alt={data.title} />
        </ImageWrapper>
        <DescriptionWrapper>
          <CaseNumber>
            {data.userCaseText ? data.userCaseText : 'User case'}
          </CaseNumber>
          <Title>{data.title}</Title>
          <Description>{data.description}</Description>
          <StyledUiTextLink text={data.linkText} href={link} />
        </DescriptionWrapper>
      </Wrapper>
    </Root>
  );
};

const Root = styled.article`
  display: flex;

  background-color: ${COLORS.white};
  box-shadow: 0px 5px 26px rgba(202, 202, 219, 0.33);
  border-radius: 20px;
`;

const Wrapper = styled.div`
  padding: 40px;
  display: flex;
  align-items: center;
  @media (max-width: 1300px) {
    padding: 25px;
  }
  @media (max-width: 1150px) {
    flex-direction: column;
    width: 100%;
  }
  @media (max-width: 768px) {
    padding: 30px 15px;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  max-width: 248px;
  margin-right: 24px;
  overflow: hidden;
  border-radius: 22px;
  img {
    width: 100%;
  }
  @media (max-width: 1150px) {
    margin-right: 0;
    margin-bottom: 16px;
    max-width: 200px;
  }
  @media (max-width: 768px) {
    max-width: 128px;
  }
`;

const DescriptionWrapper = styled.div`
  max-width: 248px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  @media (max-width: 1150px) {
    max-width: none;
  }
`;

const CaseNumber = styled.p`
  ${TYPOGRAPHY.body1SemiBold18};
  color: ${COLORS.grayDarker};
  margin-bottom: 20px;
  text-transform: uppercase;

  ::after {
    counter-increment: userCase;
    content: ' ' counter(userCase);
  }
  @media (max-width: 768px) {
    margin-bottom: 12px;
  }
`;

const Title = styled.h3`
  ${TYPOGRAPHY.headlineMedium22};
  color: ${COLORS.black2};
  margin-bottom: 20px;
  @media (max-width: 768px) {
    margin-bottom: 16px;
  }
`;

const Description = styled.p`
  ${TYPOGRAPHY.body2Light16};
  margin-bottom: 20px;
  color: ${COLORS.black2};
  @media (max-width: 768px) {
    margin-bottom: 16px;
  }
`;

const StyledUiTextLink = styled(UiTextLink)`
  margin-top: auto;
`;

export default Case;
