import { LocaleContext } from '@/contexts/LocaleContext';
import { graphql, useStaticQuery } from 'gatsby';
import { useContext } from 'react';

interface ReasonData {
  id: string;
  text: string;
  locale: string;
}

export function useReasonsForRequestVehicleData(): ReasonData[] {
  const locale = useContext(LocaleContext);
  const { allStrapiReasonForRequestingVehicleData } = useStaticQuery(graphql`
    query {
      allStrapiReasonForRequestingVehicleData {
        nodes {
          locale
          text
          id
        }
      }
    }
  `);

  return allStrapiReasonForRequestingVehicleData.nodes.filter(
    (item) => item.locale === locale,
  );
}
