import { COLORS, TYPOGRAPHY } from '@/constants';
import { Arrow } from '@/ui/iconComponents/Arrow';
import React, { FC, useRef } from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

interface AccordionItemProps {
  question: string;
  answer: string;
  className?: string;
  onToggle: () => void;
  isActive: boolean;
}

const AccordionItem: FC<AccordionItemProps> = ({
  question,
  answer,
  className,
  onToggle,
  isActive,
}) => {
  const contentRef = useRef<HTMLDivElement>();
  const answerWrapperStyle = isActive
    ? { maxHeight: contentRef.current.scrollHeight }
    : { maxHeight: '0px' };

  return (
    <Root className={`${className} ${isActive ? 'active' : ''}`}>
      <Wrapper>
        <div>
          <Button onClick={onToggle}>
            {question}
            <ArrowWrapper>
              <StyledArrow />
            </ArrowWrapper>
          </Button>
        </div>
        <AnswerWrapper ref={contentRef} style={answerWrapperStyle}>
          <Answer>
            <ReactMarkdown>{answer}</ReactMarkdown>
          </Answer>
        </AnswerWrapper>
      </Wrapper>
    </Root>
  );
};

const Root = styled.li`
  background-color: ${COLORS.lightGray3};
  border-radius: 20px;
`;

const Wrapper = styled.div`
  padding: 20px;
  @media (max-width: 768px) {
    padding: 30px 15px;
  }
`;

const ArrowWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 40px;
  height: 40px;
  background-color: ${COLORS.white};
  transition: background-color 0.3s ease;
  border-radius: 8px;
  margin-left: 20px;
`;

const StyledArrow = styled(Arrow)`
  transform: rotate(90deg);
  transition: transform 0.3s ease;
  .active & {
    transform: rotate(270deg);
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  ${TYPOGRAPHY.body1SemiBold18};
  color: ${COLORS.black2};
  margin-right: 20px;
  width: 100%;
  cursor: pointer;
  text-align: left;
`;

const AnswerWrapper = styled.div`
  transition: max-height 0.3s;
  padding-right: 45px;
  max-height: 0;
  overflow: hidden;
  @media (max-width: 1024px) {
    padding-right: 0;
  }
`;

const Answer = styled.div`
  padding-top: 20px;
  p {
    ${TYPOGRAPHY.body1Regular18};
    color: ${COLORS.black2};
  }
  li {
    ${TYPOGRAPHY.body2Regular16};
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding-bottom: 10px;
    color: ${COLORS.black2};
    border-bottom: 1px solid ${COLORS.lightGray};
    margin-bottom: 20px;
    em {
      ${TYPOGRAPHY.body2Light16};
      font-style: normal;
      min-width: 268px;
    }
    :last-child {
      margin-bottom: 0;
      border: 0;
    }
  }
  a {
    ${TYPOGRAPHY.body2Regular16};
    color: ${COLORS.purple};
    :hover {
      text-decoration: underline;
    }
  }
  @media (max-width: 768px) {
    li {
      em {
        min-width: 130px;

        text-align: right;
      }
    }
  }
`;

export default AccordionItem;
