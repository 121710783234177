import { LocaleContext } from '@/contexts/LocaleContext';
import { FaqsData } from '@/types';
import { graphql, useStaticQuery } from 'gatsby';
import { useContext } from 'react';

export function useFaqs(): FaqsData[] {
  const locale = useContext(LocaleContext);
  const { allStrapiFaqs } = useStaticQuery(graphql`
    query GET_FAQS {
      allStrapiFaqs {
        nodes {
          id
          question
          answer
          locale
        }
      }
    }
  `);

  return allStrapiFaqs.nodes.filter((item) => item.locale === locale);
}
