import React, { FC, useContext, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import ReCAPTCHA from 'react-google-recaptcha';
import { Container } from '../Container';
import { TagSection } from '../TagSection';
import { ImageDataValue } from '@/types';
import { COLORS, TYPOGRAPHY } from '@/constants';
import { Field, Form } from 'react-final-form';
import { TextField } from '../TextField';
import { UiButton } from '@/ui/components/UiButton';
import {
  composeValidators,
  emailValidate,
  nameValidate,
  requiredValidate,
} from '@/helpers/fieldValidate';
import { FieldSelect } from '../FieldSelect';
import { useMutation } from 'graphql-hooks';
import { useContactReasons } from '@/hooks/useContactReasons';
import { DatePickerField } from '@/components/DatePickerField';
import { InputTimeField } from '../InputTimeField';
import dayjs from 'dayjs';
import { SuccessModal } from '../SuccessModal';
import { LocaleContext } from '@/contexts/LocaleContext';
import { useLocaleData } from '@/hooks/useLocaleData';
import { FormApi } from 'final-form';
import { PhoneField } from '../PhoneField';

interface ContactUs {
  sectionData: {
    tagText?: string;
    title: string;
    buttonText: string;
    image: ImageDataValue;
  };
  titleFont?: string;
}

const CREATE_USER_MUTATION = `mutation CreateUser($name: String!, $reason: String!, $date: String!, $time: String!, $email: String!, $phone: String!) {
  createContactUser(input: {data: {
    name: $name,
    reason: $reason,
    date: $date,
    time: $time,
    email: $email,
    phone: $phone
  
  }}) {
    contactUser {
      
        id
        name
        reason
        date
        time
        email
        phone
    }
    
    
  }
}`;

interface FormValues {
  name: string;
  reason: {
    label: string;
    value: string;
  };
  date: string;
  time: string;
  email: string;
  phone: string;
}

const defaultFormValues = {
  date: '',
  time: '',
  name: '',
  phone: '',
  email: '',
  reason: '',
};

const ContactUs: FC<ContactUs> = ({
  sectionData,
  titleFont = TYPOGRAPHY.title1SemiBold64,
}) => {
  const locale = useContext(LocaleContext);
  const [createUser] = useMutation(CREATE_USER_MUTATION);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState('');
  const reasons = useContactReasons();
  const dataLocale = useMemo(
    () => useLocaleData(locale, ['contactUs', 'inputPlaceholders', 'buttons']),
    [locale],
  );

  const handleSubmit = async (values: FormValues, form: FormApi) => {
    const { data, error } = await createUser({
      variables: {
        ...values,
        reason: values.reason.label,
        date: `${dayjs(values.date).format('DD.MM.YYYY')}`,
      },
    });

    if (data) {
      setIsOpenModal(true);
      Object.keys(values).forEach((key) => {
        form.change(key, undefined);
        form.resetFieldState(key);
      });
    }
  };

  const reasonOptions = reasons.map((item) => ({
    label: item.reason,
    value: item.reason,
  }));

  return (
    <Root>
      <SuccessModal
        title={dataLocale.contactUs.successModalTitle}
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
      />
      <StyledContainer>
        <Content>
          {sectionData.tagText.trim() && (
            <StyledTagText text={sectionData.tagText} />
          )}
          <Title $font={titleFont}>{sectionData.title}</Title>
          <FormWrap>
            <Form
              initialValues={defaultFormValues}
              onSubmit={(e: FormValues, form: FormApi) => handleSubmit(e, form)}
              render={({ handleSubmit, submitting, invalid }) => (
                <form>
                  <InputsWrapper>
                    <Field
                      name="date"
                      render={(props) => {
                        return <DatePickerField {...props} />;
                      }}
                    />
                    <Field
                      name="time"
                      render={(props) => {
                        return <InputTimeField {...props} />;
                      }}
                    />
                    <FullField
                      name="name"
                      type="text"
                      placeholder={dataLocale.inputPlaceholders.fullName}
                      validate={composeValidators(
                        requiredValidate,
                        nameValidate,
                      )}
                      component={TextField}
                    />
                    <FullField
                      name="email"
                      type="email"
                      placeholder={dataLocale.inputPlaceholders.email}
                      validate={composeValidators(
                        requiredValidate,
                        emailValidate,
                      )}
                      component={TextField}
                    />

                    <FullField
                      name="phone"
                      placeholder={dataLocale.inputPlaceholders.phoneNumber}
                      component={PhoneField}
                    />

                    <FullField
                      name="reason"
                      placeholder={dataLocale.inputPlaceholders.reasonRequest}
                      component={FieldSelect}
                      validate={composeValidators(requiredValidate)}
                      options={reasonOptions}
                    />
                    <ReCaptchaWrapper>
                      <ReCAPTCHA
                        sitekey="6LfbowoeAAAAANHDc0iUfox3jP4WG14nrO7Pk6Ua"
                        onChange={(e: string) => setRecaptchaValue(e)}
                      />
                    </ReCaptchaWrapper>
                  </InputsWrapper>
                  <StyledUiButton
                    text={dataLocale.buttons.callMeBack}
                    onClick={handleSubmit}
                    customTheme="primarySmall"
                    disabled={submitting || invalid || !recaptchaValue}
                  />
                </form>
              )}
            />
          </FormWrap>
        </Content>
        <ImageWrap
          style={{
            backgroundImage: `url(${sectionData.image.localFile.publicURL})`,
          }}
        ></ImageWrap>
      </StyledContainer>
    </Root>
  );
};

const Root = styled.section``;

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 90px;
  padding-bottom: 200px;
  @media (max-width: 1024px) {
    padding-top: 50px;
    padding-bottom: 100px;
  }
`;

const Content = styled.div`
  max-width: 615px;
  margin-right: 15px;
  @media (max-width: 1024px) {
    max-width: none;
    margin-right: 0;
    flex-grow: 1;
  }
`;

const StyledTagText = styled(TagSection)`
  margin-bottom: 20px;
  @media (max-width: 768px) {
    margin-bottom: 24px;
  }
`;

const Title = styled.h2<{ $font: string }>`
  ${({ $font }) => $font}
  color: ${COLORS.black2};
  margin-bottom: 40px;

  @media (max-width: 1024px) {
    ${TYPOGRAPHY.title2SemiBold48};
  }
  @media (max-width: 768px) {
    ${TYPOGRAPHY.headlineSemiBold22};
    margin-bottom: 32px;
  }
`;

const FormWrap = styled.div`
  max-width: 392px;
  @media (max-width: 1024px) {
    margin: 0 auto;
  }
`;

const InputsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px 24px;
  margin-bottom: 40px;
  @media (max-width: 768px) {
    gap: 20px;
  }
`;

const FullField = styled(Field)`
  grid-column: 1/-1;
`;

const StyledUiButton = styled(UiButton)`
  width: 100%;
  grid-column: 1/-1;
`;

const ImageWrap = styled.div`
  position: relative;
  flex-grow: 1;
  max-width: 608px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: ${COLORS.lightPurple};
  border-radius: 40px;
  ::before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 117.1%;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

const ReCaptchaWrapper = styled.div`
  grid-column: 1/-1;
`;

export default ContactUs;
